::-webkit-scrollbar {
    width: 7px;
}
::-webkit-scrollbar-track {
    background: #cfcfcf;
}
::-webkit-scrollbar-thumb {
    background: #a5a5a5;
}

* {
    text-decoration: none;
}
